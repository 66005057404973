.kustomkaruselli img{
  height: 300px !important;
  object-fit: cover;
}
section{
  padding: 15px;
}
footer {
  text-align: center;
  margin: 0 auto;
  background-color: #212529;
  color: white;
  padding: 10px;
}
#mypic{
  max-width: 200px;
  border-radius: 50%;
  border-image: round;
  box-shadow: 3 2 3 black;
}
#hekuma{
  width: 100%;
  max-width: 500px;
  border-image: round;
  box-shadow: 3 2 3 black;
}
#sydan{
  width:16px !important;
  height: 16px !important;
}